import {Form, Input, Space, Button, message, Spin, Row, Col, Divider} from "antd";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from 'axios';
import {CloseOutlined, SaveOutlined} from '@ant-design/icons';
import {useTranslation} from "react-i18next";
import {parsePhoneNumberFromString, isValidPhoneNumber} from 'libphonenumber-js';
import GlobalSelect from "../../generales/GlobalSelect";

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});

const FormAddVehiculos = ({accion}) => {
    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
    };

    const {t, i18n} = useTranslation();
    const [messageApi, contextHolder] = message.useMessage();
    const [spinning, setSpinning] = useState(false);
    const [spinTitle, setSpinTitle] = useState(t('global.processing_data'));
    const [title] = useState((accion === "mod") ? t('vehicle.edit_title') : t('vehicle.add_title'));
    const navigate = useNavigate();
    const {id} = useParams();
    const [form] = Form.useForm();
    const [repartidor, setRepartidor] = useState([]);
    const [estadoVehiculo, setEstadoVehiculo] = useState([]);
    const [tipoVehiculo, setTipoVehiculo] = useState([]);


    const validateEmail = (rule, value, callback) => {
        if (value) {
            const emailRegex = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/;
            if (accion !== "pass" && !emailRegex.test(value)) {
                callback('Correo Inválido / Invalid email address');
            } else {
                callback();
            }
        }
    };

    // const validatePhoneNumber = (rule, value) => {
    //     if (!value) {
    //         return Promise.reject('El número de teléfono es obligatorio');
    //     }
    //
    //     // Usa parsePhoneNumberFromString para validar con distintos formatos
    //     const phoneNumberUS = parsePhoneNumberFromString(value, 'US');
    //     const phoneNumberCuba = parsePhoneNumberFromString(value, 'CU');
    //
    //     // Comprueba si el número es válido para EE. UU. o Cuba
    //     if ((phoneNumberUS && phoneNumberUS.isValid()) || (phoneNumberCuba && phoneNumberCuba.isValid())) {
    //         return Promise.resolve();
    //     }
    //
    //     return Promise.reject('Número de teléfono no válido para EE. UU. o Cuba');
    // };

    const cargarRepartidor = () => {
        setSpinning(true);
        let aux = [];
        // let start = 0;
        // let limit = 1000;

        Axios.post("mts/getUssers",
            {
                // "start": start,
                // "limit": limit,
                "filtros": {
                    "idrol": 4
                }
            },
            {
                headers: headers
            }
        ).then(response => {
            setSpinning(false);
            if (response.data.data.length > 0) {
                for (let i = 0; i < response.data.data.length; i++) {
                    aux.push({
                        id: response.data.data[i].idusuario,
                        // idrepartidor:response.data.data[i].idusuario,
                        nombre: response.data.data[i].fullname
                    });
                }
            }
            setRepartidor(aux);
        }).catch(error => {
            setSpinning(false);
            setRepartidor(aux);
        })
    };

    const cargarEstadoVehiculo = () => {
        setSpinning(true);
        let aux = [];
        // let start = 0;
        // let limit = 1000;

        Axios.post("sd/getEstadoVehiculo",
            {
                // "start": start,
                // "limit": limit,
                // "filtros":{
                //     "idrol": 4
                // }
            },
            {
                headers: headers
            }
        ).then(response => {
            setSpinning(false);
            if (response.data.data.length > 0) {
                for (let i = 0; i < response.data.data.length; i++) {
                    aux.push({
                        id: response.data.data[i].idestadovehiculo,
                        nombre: response.data.data[i].denominacion
                    });
                }
            }
            setEstadoVehiculo(aux);
        }).catch(error => {
            setSpinning(false);
            setEstadoVehiculo(aux);
        })
    };

    const cargarTipoVehiculo = () => {
        setSpinning(true);
        let aux = [];
        // let start = 0;
        // let limit = 1000;

        Axios.post("sd/getTipoVehiculo",
            {
                // "start": start,
                // "limit": limit,
                "filtros": {
                    "activo": 1
                }
            },
            {
                headers: headers
            }
        ).then(response => {
            setSpinning(false);
            if (response.data.data.length > 0) {
                for (let i = 0; i < response.data.data.length; i++) {
                    aux.push({
                        id: response.data.data[i].idtipovehiculo,
                        nombre: response.data.data[i].denominacion
                    });
                }
            }
            setTipoVehiculo(aux);
        }).catch(error => {
            setSpinning(false);
            setTipoVehiculo(aux);
        })
    };

    useEffect(() => {

        cargarRepartidor();
        cargarEstadoVehiculo();
        cargarTipoVehiculo();

        if (accion === "mod") {
            if (!sessionStorage.getItem('sessionDataStorage')) {
                navigate('/vehiculo');
            } else {
                let sessionDataStorage = JSON.parse(sessionStorage.getItem('sessionDataStorage'));
                console.log({sessionDataStorage});
                form.setFieldsValue({
                    "marca": sessionDataStorage.marca,
                    "modelo": sessionDataStorage.modelo,
                    "matricula": sessionDataStorage.matricula,
                    "capacidad_carga": sessionDataStorage.capacidad_carga,
                    "longitud_carga": sessionDataStorage.longitud_carga,
                    "ancho_carga": sessionDataStorage.ancho_carga,
                    "altura_carga": sessionDataStorage.altura_carga,
                    "comentarios": sessionDataStorage.comentarios,
                    "idusuario": sessionDataStorage.idusuario,
                    "idestadovehiculo": sessionDataStorage.idestadovehiculo,
                    "idtipovehiculo": sessionDataStorage.idtipovehiculo,
                });
            }
        }
    }, []);

    const cancelar = () => {
        navigate('/vehiculo');
    };

    const onFinish = (values) => {
        values.idvehiculo = id;
        setSpinning(true);

        let message;

        if (accion === "add") {
            setSpinTitle(t('global.processing_data'));
            Axios.post("sd/addVehiculo", values, {headers})
                .then(response => {
                    setSpinning(false);
                    message = response.data.message;
                    if (response.data.success) {
                        messageApi.open({
                            type: 'success',
                            content: response.data.message,
                        });
                        setTimeout(messageApi.destroy, 3500);
                        navigate('/vehiculo');
                    } else {
                        messageApi.open({type: 'error', content: message});
                        setTimeout(messageApi.destroy, 3500);
                    }
                })
                .catch(() => {
                    setSpinning(false);
                    messageApi.open({type: 'error', content: message});
                    setTimeout(messageApi.destroy, 3500);
                });
        } else if (accion === "mod") {
            setSpinTitle(t('global.processing_data'));
            Axios.post("sd/updateVehiculo", values, {headers})
                .then(response => {
                    setSpinning(false);
                    message = response.data.message;
                    if (response.data.success) {
                        messageApi.open({
                            type: 'success',
                            content: response.data.message,
                        });
                        setTimeout(messageApi.destroy, 2500);
                        navigate('/vehiculo');
                    } else {
                        messageApi.open({type: 'error', content: message});
                        setTimeout(messageApi.destroy, 2500);
                    }
                })
                .catch(() => {
                    setSpinning(false);
                    messageApi.open({type: 'error', content: message});
                    setTimeout(messageApi.destroy, 2500);
                });
        }
    };

    return (
        <Spin spinning={spinning} tip={spinTitle} size={"large"}>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '10px'
            }}>
                <p style={{
                    textAlign: "center",
                    margin: "10px 0",
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: "#2c3e50",
                    fontFamily: "'Roboto', sans-serif",
                    borderBottom: "2px solid #61768B",
                    paddingBottom: "5px",
                    textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
                }}>
                    {title}
                </p>
                {contextHolder}
                <Row justify="center" style={{width: "100%"}}>
                    <Col xs={24} sm={20} md={18} lg={16} xl={14}>
                        <Form
                            form={form}
                            autoComplete="on"
                            onFinish={onFinish}
                            layout="vertical"
                            initialValues={{activo: 1}}
                        >
                            {/* Fila con 3 columnas */}
                            <Row gutter={16}>
                                <Col xs={24} sm={8}>
                                    <Form.Item
                                        name="marca"
                                        label={t('vehicle.marca')}
                                        rules={[{required: true, message: 'Por favor debe poner la marca!'}]}
                                    >
                                        <Input tabIndex={1}/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={8}>
                                    <Form.Item
                                        name="modelo"
                                        label={t('vehicle.modelo')}
                                        rules={[{required: true, message: 'Por favor debe poner el modelo!'}]}
                                    >
                                        <Input tabIndex={2}/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={8}>
                                    <Form.Item
                                        name="matricula"
                                        label={t('vehicle.matricula')}
                                        rules={[{required: true, message: 'Por favor debe poner la matricula!'}]}
                                    >
                                        <Input tabIndex={3}/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            {/* Sección de características técnicas */}
                            <Divider orientation="left" style={{
                                color: '#2C3E50',
                                borderColor: '#2C3E50'
                            }}>{t('vehicle.caracteristicas_tecnicas')}</Divider>

                            {/* Segunda fila con 3 columnas */}
                            <Row gutter={16}>
                                <Col xs={24} sm={8}>
                                    <Form.Item
                                        name="idtipovehiculo"
                                        label={t('vehicle.tipo_vehiculo')}
                                        rules={[{
                                            required: true,
                                            message: 'Por favor debe seleccionar el tipo de vehículo!'
                                        }]}
                                    >
                                        <GlobalSelect loading={spinning} valueName={'idtipovehiculo'}
                                                      data={tipoVehiculo} tabIndex={4}/>
                                    </Form.Item>

                                    <Form.Item
                                        name="ancho_carga"
                                        label={t('vehicle.ancho_carga')}
                                        rules={[{required: true, message: 'Por favor debe poner el ancho de carga!'},
                                            {
                                                pattern: /^\d+(\.\d+)?$/,
                                                message: 'Solo se permiten números enteros o con comas!',
                                            }]}
                                    >
                                        <Input tabIndex={6}
                                               onKeyPress={(event) => {
                                                   const keyCode = event.which || event.keyCode;
                                                   const char = String.fromCharCode(keyCode);
                                                   const value = event.target.value;

                                                   // Permitir solo números, un punto y asegurarse de que no haya más de un punto
                                                   if (!/[0-9.]/.test(char) || (char === '.' && value.includes('.'))) {
                                                       event.preventDefault();
                                                   }
                                               }}/>
                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={8}>
                                    <Form.Item
                                        name="capacidad_carga"
                                        label={t('vehicle.capacidad_carga')}
                                        rules={[
                                            {required: true, message: 'Por favor debe poner la capacidad de carga!'},
                                            {
                                                pattern: /^\d+(\.\d+)?$/,
                                                message: 'Solo se permiten números enteros o con comas!',
                                            }
                                        ]}
                                    >
                                        <Input tabIndex={5}
                                               onKeyPress={(event) => {
                                                   const keyCode = event.which || event.keyCode;
                                                   const char = String.fromCharCode(keyCode);
                                                   const value = event.target.value;

                                                   // Permitir solo números, un punto y asegurarse de que no haya más de un punto
                                                   if (!/[0-9.]/.test(char) || (char === '.' && value.includes('.'))) {
                                                       event.preventDefault();
                                                   }
                                               }}/>
                                    </Form.Item>
                                    <Form.Item
                                        name="longitud_carga"
                                        label={t('vehicle.longitud_carga')}
                                        rules={[{required: true, message: 'Por favor debe poner la longitud de carga!'},
                                            {
                                                pattern: /^\d+(\.\d+)?$/,
                                                message: 'Solo se permiten números enteros o con comas!',
                                            }]}
                                    >
                                        <Input tabIndex={7}
                                               onKeyPress={(event) => {
                                                   const keyCode = event.which || event.keyCode;
                                                   const char = String.fromCharCode(keyCode);
                                                   const value = event.target.value;

                                                   // Permitir solo números, un punto y asegurarse de que no haya más de un punto
                                                   if (!/[0-9.]/.test(char) || (char === '.' && value.includes('.'))) {
                                                       event.preventDefault();
                                                   }
                                               }}/>
                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={8}>
                                    <Form.Item
                                        style={{marginBottom: '55px'}} // Ajusta el tamaño del espacio
                                    >
                                        {/* Espacio vacío */}
                                    </Form.Item>
                                    <Form.Item
                                        name="altura_carga"
                                        label={t('vehicle.altura_carga')}
                                        rules={[{required: true, message: 'Por favor debe poner la altura de carga!'},
                                            {
                                                pattern: /^\d+(\.\d+)?$/,
                                                message: 'Solo se permiten números enteros o con comas!',
                                            }]}
                                    >
                                        <Input tabIndex={8}
                                               onKeyPress={(event) => {
                                                   const keyCode = event.which || event.keyCode;
                                                   const char = String.fromCharCode(keyCode);
                                                   const value = event.target.value;

                                                   // Permitir solo números, un punto y asegurarse de que no haya más de un punto
                                                   if (!/[0-9.]/.test(char) || (char === '.' && value.includes('.'))) {
                                                       event.preventDefault();
                                                   }
                                               }}/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            {/* Sección de Estado operativo */}
                            <Divider orientation="left" style={{
                                color: '#2C3E50',
                                borderColor: '#2C3E50'
                            }}>{t('vehicle.estado_operativo')}</Divider>

                            {/* Fila con 2 columnas */}
                            <Row gutter={16}>
                                <Col xs={24} sm={8}>
                                    <Form.Item
                                        name="idestadovehiculo"
                                        label={t('vehicle.estado')}
                                        rules={[{required: false, message: 'Por favor debe seleccionar el estado!'}]}
                                    >
                                        <GlobalSelect disabled={true} loading={spinning} valueName={'idestadovehiculo'}
                                                      data={estadoVehiculo} tabIndex={9}/>
                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={8}>
                                    <Form.Item
                                        name="idusuario"
                                        label={t('vehicle.repartidor')}
                                        rules={[{
                                            required: false,
                                            message: 'Por favor debe seleccionar el repartidor!'
                                        }]}
                                    >
                                        <GlobalSelect disabled={accion === "mod"} loading={spinning}
                                                      valueName={'idusuario'} data={repartidor} tabIndex={10}/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            {/* Tercera fila con una columna completa */}
                            <Row gutter={16}>
                                <Col xs={24}>
                                    <Form.Item
                                        name="comentarios"
                                        label={t('vehicle.comentarios')}
                                        rules={[{required: false, message: 'Por favor debe poner los comentarios!'}]}
                                    >
                                        <Input.TextArea rows={3} tabIndex={11}/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            {/* Botones de acción */}
                            <Form.Item style={{textAlign: "center"}}>
                                <Space>
                                    <Button icon={<CloseOutlined/>} danger onClick={cancelar}>
                                        {t('global.cancel')}
                                    </Button>
                                    <Button icon={<SaveOutlined/>} type="primary" htmlType="submit">
                                        {t('global.save')}
                                    </Button>
                                </Space>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>


            </div>
        </Spin>
    );
};

export default FormAddVehiculos;
