import React, { useContext, useEffect, useState } from 'react';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Layout, Menu, Button, Row, Col, Select, Grid } from 'antd';
import { BrowserRouter } from "react-router-dom";
import { LoginContext } from "../components/seguridad/servicios/LoginServicios";
import UsuarioDropdown from "../generales/SessionDropdown";
import RoutersSystem from './Routers';
import MenuPrincipal from '../generales/MenuPricipal';
import { useTranslation } from "react-i18next";

const { Header, Sider, Content } = Layout;
const { useBreakpoint } = Grid;

const Home = () => {
    const { logout } = useContext(LoginContext);
    const { t, i18n } = useTranslation();
    const [idioma, setIdioma] = useState("es");
    const [collapsed, setCollapsed] = useState(false);
    const screens = useBreakpoint();

    if (!sessionStorage.getItem('idusuario') ||
        !sessionStorage.getItem('token') ||
        !sessionStorage.getItem('info_user')) {
        logout();
    }

    // Cambiar el idioma
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setIdioma(lng);
        sessionStorage.setItem("idioma", lng);
    };

    useEffect(() => {
        const userLang = sessionStorage.getItem("idioma") || navigator.language || navigator.userLanguage;
        const preferredLang = userLang.startsWith('es') ? 'es' : 'en';
        changeLanguage(preferredLang);
    }, []);

    return (
        <BrowserRouter>
            <Layout style={{ minHeight: '100vh' }}>
                <Sider
                    trigger={null}
                    collapsible
                    collapsed={collapsed}
                    style={{ background: '#001529' }}
                    width={screens.md ? 200 : 80} // Ajuste de ancho en diferentes tamaños de pantalla
                >
                    <MenuPrincipal collapsed={collapsed} />
                </Sider>
                <Layout>
                    <Header
                        className="header-layout"
                        style={{
                            backgroundColor: '#61768B',
                            padding: screens.md ? '0 24px' : '0 12px',
                        }}
                    >
                        <Row
                            justify="space-between"
                            align="middle"
                            style={{ height: '100%' }}
                            wrap={false}
                        >
                            <Col>
                                <Button
                                    type="text"
                                    icon={
                                        collapsed ? (
                                            <MenuUnfoldOutlined style={{ color: "#FFFFFF" }} />
                                        ) : (
                                            <MenuFoldOutlined style={{ color: "#FFFFFF" }} />
                                        )
                                    }
                                    onClick={() => setCollapsed(!collapsed)}
                                    style={{
                                        fontSize: screens.md ? '20px' : '16px',
                                        width: screens.md ? '180px' : 'auto',
                                        height: '54px',
                                        color: "white",
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    {/* Mostrar texto solo en pantallas medianas y grandes */}
                                    {screens.md && 'SmartDelivery'}
                                </Button>
                            </Col>
                            <Col style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                <Select
                                    value={idioma}
                                    onChange={changeLanguage}
                                    style={{
                                        width: screens.md ? 130 : 80,
                                        textAlign: "left",
                                        color: 'black',
                                        border: 'none',
                                    }}
                                    options={[
                                        {
                                            value: "es",
                                            label: (
                                                <div style={{ display: 'flex', alignItems: 'center', color: 'black' }}>
                                                    <span role="img" aria-label="Español" style={{ marginRight: 8 }}>🇪🇸</span>
                                                    {screens.md && 'Español'}
                                                </div>
                                            )
                                        },
                                        {
                                            value: "en",
                                            label: (
                                                <div style={{ display: 'flex', alignItems: 'center', color: 'black' }}>
                                                    <span role="img" aria-label="Inglés" style={{ marginRight: 8 }}>🇺🇸</span>
                                                    {screens.md && 'Inglés'}
                                                </div>
                                            )
                                        },
                                    ]}
                                />
                                <UsuarioDropdown />
                            </Col>
                        </Row>
                    </Header>
                    <Content
                        style={{
                            minHeight: '280px',
                            backgroundColor: 'white',
                            padding: screens.md ? '24px' : '12px',
                        }}
                    >
                        <RoutersSystem />
                    </Content>
                </Layout>
            </Layout>
        </BrowserRouter>
    );
};

export default Home;
