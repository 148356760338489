import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Button, Table, message, Form, Modal, Select, Spin, Space, Row, Col, Tooltip} from 'antd';
import {Link, useNavigate} from "react-router-dom";
import {MdAddHome} from "react-icons/md";
import {CarOutlined, CheckCircleOutlined, CloseCircleOutlined, PlusOutlined} from '@ant-design/icons';
import {
    AiFillAlert,
    AiFillDelete,
    AiFillEdit,
} from "react-icons/ai";
import {IoPersonSharp, IoReload, IoCheckmarkCircleSharp, IoAddCircle  } from "react-icons/io5";
import Search from "antd/es/input/Search";
import {useTranslation} from "react-i18next";
import _ from "lodash";


export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});
const { Option } = Select;


const TipoVehiculo = () => {

    if(sessionStorage.getItem('user_mod'))
       sessionStorage.removeItem('user_mod');

    const { t, i18n } = useTranslation();
    const [bottom, setBottom] = useState('bottomCenter');
    const [original, setOriginal] = useState([]);
    const [data, setData] = useState([]);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [spinning, setSpinning] = useState(false);
    const [spinTitle, setSpinTitle] = useState(t('global.loading'));
    const [messageApi, contextHolder] = message.useMessage();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading] = useState(false);
    const [searchValue, setSearchValue] = useState(''); // Estado para controlar el valor del input

    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
      };

    useEffect(() => {
        recargar();
    }, []);

    const filterData = data => formatter => data.map( item => ({
        text: formatter(item),
        value: formatter(item)
    }));

    const columns = [
        {
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('vehicletype.denomination')}</p>,
            dataIndex: 'denominacion',
            width: 200,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.denominacion), _.isEqual),
            onFilter: (value, record) => record.denominacion.startsWith(value),
        },{
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>{t('vehicletype.active')}</p>,
            dataIndex: 'activo',
            // width: 200,
            render: (text) => (text === "1") ? <IoCheckmarkCircleSharp size={20} color={'#0d6efd'}/> :
                <IoCheckmarkCircleSharp size={20} color={'red'}/>,
            // filterSearch: true,
            // filters: _.uniqWith(filterData(data)(i => i.activo), _.isEqual),
            // onFilter: (value, record) => record.activo.startsWith( value ),
        }
    ];

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: data.length, // Asegúrate de tener el total de elementos aquí
    });

    const handleTableChange = (newPagination) => {
        setPagination({
            ...pagination,
            current: newPagination.current,
            pageSize: newPagination.pageSize,
        });
    };

    const recargar = () => {
        //limpiar la busqueda del componente Search
        setSearchValue('');

        setSpinTitle(t('global.loading'));
        setSpinning(true);
        setSelectedRowKeys([]);
        let aux = [];

        let start = 0;
        let limit = 1000;

        Axios.post("sd/getTipoVehiculo",
            {
                // "start": start,
                // "limit": limit,
                // "filtro":{
                //     "idrol": 1
                // }
            },
            {
                headers: headers
            }
        ).then(response => {
            setSpinning(false);
            if (response.data.data.length > 0) {
                for (let i = 0; i < response.data.data.length; i++) {
                    aux.push({
                        key: response.data.data[i].idtipovehiculo,
                        idtipovehiculo: response.data.data[i].idtipovehiculo,
                        denominacion: response.data.data[i].denominacion,
                        activo: response.data.data[i].activo
                    });
                }
            }

            setData(aux);
            setOriginal(aux);
        }).catch(error => {
            setSpinning(false);
        })
    };

    const getInfoForm = async (id) => {
        for(let i=0; i<data.length; i++){
            if(data[i].idtipovehiculo === id){
                return data[i];
            }
        }
        return [];
    };

    const modificar = async () => {
        form.resetFields();
        const row = await getInfoForm(selectedRowKeys[0]);
        sessionStorage.setItem('user_mod', JSON.stringify(row));
    };

    const eliminar = (e) => {
        Modal.confirm({
            title: 'Eliminar datos!',
            cancelText:'Cancelar',
            okText:'Aceptar',
            okType:'primary',
            content: (
                <div>
                    <span >{t('vehicletype.delete_data')}</span>
                </div>
            ),
            onOk() {
                setSpinTitle(t('global.processing_data'));
                setSpinning(true);
                Axios.delete('sd/deleteTipoVehiculo',
                    {
                      data: {
                        "idtipovehiculo": selectedRowKeys
                      },
                      headers: headers
                    }
                ).then(response => {
                    setSpinning(false);
                    if(response.data.success){
                        messageApi.open({
                            type: 'success',
                            content: response.data.message,
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                        recargar();
                    }else{
                        messageApi.open({
                            type: 'error',
                            content: t('info.error'),
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                    }
                })
                    .catch(error => {
                        setSpinning(false);
                        messageApi.open({
                            type: 'error',
                            content: t('info.error'),
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                    });
            },
        });
    };

    const onSearch = (value, _e, info) => {
        filterLocal(value);
    };

    const filterLocal = (value) => {
        if(value === "" || value === null){
            setData(original);
        }else{
            let aux = [];
            original.forEach(function(option) {
                if (option.denominacion.toUpperCase().indexOf(value.toUpperCase()) !== -1) {
                    aux.push(option);
                }
            });
            setData(aux);
        }
    };

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const selectRowUpdate  = async (record) => {
        setSelectedRowKeys([record.key]);
        form.resetFields();
        let row = [];
        for(let i=0;i<data.length;i++){
            if(data[i].idtipovehiculo === record.key){
                row = data[i];
            }
        }
       
        sessionStorage.setItem('user_mod', JSON.stringify(row));
        navigate(`/editTipoVehiculo/${record.key}`)
        
    };

    function activacion(e, activar) {

        Modal.confirm({
            title: (activar) ? 'Activar vehículo' : 'Desactivar vehículo',
            cancelText: 'Cancelar',
            okText: 'Aceptar',
            okType: 'primary',
            content: (
                <div>
                    <span>¿Desea {(activar) ? 'activar' : 'desactivar'} el vehículo seleccionado?</span>
                </div>
            ),
            onOk() {
                setSpinTitle((activar) ? 'Activando vehículo...' : 'Desactivando vehículo...');
                setSpinning(true);
                Axios.post("sd/activarDesactivarTipoVehiculo",
                    {
                        idtipovehiculo: selectedRowKeys[0],
                        activo: (activar) ? 1 : 0
                    },
                    {
                        headers: headers
                    }
                ).then(response => {
                    setSpinning(false);
                    if (response.data.success) {
                        messageApi.open({
                            type: 'success',
                            content: (activar) ? 'Vehículo activado satisfactoriamente' : 'Vehículo desactivado satisfactoriamente',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                        recargar();
                    } else {
                        messageApi.open({
                            type: 'error',
                            content: (activar) ? 'Error activando Vehículo' : 'Error desactivando Vehículo',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                    }
                })
                    .catch(error => {
                        setSpinning(false);
                        messageApi.open({
                            type: 'error',
                            content: (activar) ? 'Error activando Vehículo' : 'Error desactivando Vehículo',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);


                    });
            },
        });
    }


    return (
        <Spin spinning={spinning} tip={spinTitle} size={"large"}>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                //alignItems: 'center',
                padding: '10px'
            }}>
                <p style={{
                    textAlign: "center",
                    margin: "10px 0",
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: "#2c3e50",
                    fontFamily: "'Roboto', sans-serif",
                    borderBottom: "2px solid #61768B",
                    paddingBottom: "5px",
                    textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
                }}>
                    {t('vehicletype.title')}
                </p>
                {contextHolder}
                <div
                    style={{
                        marginBottom: 10,
                    }}
                >
                    {contextHolder}
                    <Space direction='horizontal'>
                        <Link to="/addTipoVehiculo">
                            <Tooltip title={t('vehicletype.add')}>
                                <Button style={{marginRight: "0px", marginLeft: "10px"}} type="primary" shape="circle"
                                        className="button" icon={<PlusOutlined />} disabled={loading}>

                                </Button>
                            </Tooltip>
                        </Link>
                        <Link to={`/editTipoVehiculo/${selectedRowKeys[0]}`}>
                            <Tooltip title={t('vehicletype.edit')}>
                                <Button style={{marginRight: "0px", marginLeft: "0px"}} type="primary" shape="circle"
                                        className="button" icon={<AiFillEdit/>} onClick={modificar}
                                        disabled={loading || selectedRowKeys.length != 1}>

                                </Button>
                            </Tooltip>
                        </Link>
                        <Tooltip title={t('vehicletype.activate')}>
                            <Button style={{marginRight: "0px", marginLeft: "0px"}} type="primary" shape="circle"
                                    className="button" icon={<CheckCircleOutlined size={15}/>} onClick={(e) => {
                                activacion(e, true);
                            }} disabled={loading || selectedRowKeys.length !== 1}>

                            </Button>
                        </Tooltip>

                        <Tooltip title={t('vehicletype.deactivate')}>
                            <Button style={{marginRight: "0px", marginLeft: "0px"}} type="primary" shape="circle" danger
                                    className="button" icon={<CloseCircleOutlined size={15}/>} onClick={(e) => {
                                activacion(e, false);
                            }} disabled={loading || selectedRowKeys.length !== 1}>

                            </Button>
                        </Tooltip>
                        <Tooltip title={t('vehicletype.delete')}>
                            <Button style={{marginRight: "0px", marginLeft: "0px"}} type="primary" shape="circle" danger
                                    className="button" icon={<AiFillDelete/>} onClick={eliminar}
                                    disabled={!hasSelected || loading}>

                            </Button>
                        </Tooltip>

                        <Tooltip title={t('vehicletype.update')}>
                            <Button style={{marginRight: "0px", marginLeft: "0px"}} type="primary" shape="circle"
                                    className="button" icon={<IoReload/>} onClick={recargar}
                                    loading={loading || loading}>

                            </Button>
                        </Tooltip>

                        <Search
                            placeholder={t('vehicletype.search')}
                            enterButton
                            style={{
                                width: 230,
                            }}
                            size="middle"
                            onSearch={onSearch}
                            value={searchValue} // El valor del input es controlado por el estado
                            onChange={(e) => {
                                setSearchValue(e.target.value); // Actualiza el estado con lo que se escribe
                                filterLocal(e.target.value);
                            }}
                        />
                    </Space>
                </div>

                <Row>
                    <Col span={24}>
                        <Table size="small"
                            // scroll={{
                            //     y: 350,
                            // }}
                               bordered={true}
                               pagination={{
                                   position: [bottom],
                                   current: pagination.current,
                                   pageSize: pagination.pageSize,
                                   showSizeChanger: true,
                                   pageSizeOptions: ['10', '20', '50', '100'],
                                   showTotal: (total) => `Total ${total} elementos`, // Texto que muestra el total de elementos
                               }}
                               rowSelection={rowSelection}
                               columns={columns} dataSource={data}
                               onRow={(record) => {
                                   return {
                                       onDoubleClick: async () => {
                                           // selectRowUpdate(record);
                                       }
                                   }
                               }}/>
                    </Col>
                </Row>


            </div>
        </Spin>
    );
};
export default TipoVehiculo;