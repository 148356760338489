import React, {useContext, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {Avatar, Dropdown, Menu, Row, Select, Space} from 'antd';
import { DownOutlined, UserOutlined, LogoutOutlined, LockOutlined } from '@ant-design/icons';
import {LoginContext} from "../components/seguridad/servicios/LoginServicios";
import { FormattedMessage } from 'react-intl';
import flags_es from "../views/images/flags_spanish.png";
import flags_us from "../views/images/flags_us.png";


const UsuarioDropdown = () => {

    const navigate = useNavigate();

    const {logout, wait} = useContext(LoginContext);


    if(!sessionStorage.getItem('info_user'))
        logout();

    let infor_user = (sessionStorage.getItem('info_user'))? JSON.parse(sessionStorage.getItem('info_user')):null;

    const changeMyPassword = () =>{
        navigate(`/changemypass/${infor_user.idusuario}`);
    };
    
    return (
        <>
        <Dropdown overlayStyle={{paddingTop: 4}} overlay={
            <Menu
                className='menu-sider-layout menu-perfil'
                theme="dark"
                items={[
                    {
                        key: '1',
                        label: <FormattedMessage id="menu_cambiar_password" defaultMessage="Cambiar mi contraseña" />,
                        icon:<LockOutlined/>, onClick: changeMyPassword
                    },
                    {
                      key: '2',
                      label: <FormattedMessage id="menu_cerrar_session" defaultMessage="Cerrar Sesión" />,
                      icon:<LogoutOutlined/>, onClick: logout
                    }
                ]}
            />
        }>

            <Row align='middle' className='usuario-menu'>
                <Space style={{height: 42, margin: "0px 20px"}}>
                    <Avatar size="large" icon={<UserOutlined/>} style={{
                        margin: '-5px 0 0 0',
                        backgroundColor: 'var(--tema-color-base-claro)',
                        borderRadius: 10
                    }}/>
                    <h4 style={{color: 'white'}}
                        className='trigger_option'>{(infor_user !== null) ? infor_user.correo : ""}</h4>
                    <DownOutlined className='trigger_option' color="white"/>
                </Space>
            </Row>

        </Dropdown>
        </>
    );
}

export default UsuarioDropdown;

// {key: '2', label: "Configuración", icon:<SettingOutlined/>},
// {key: '3', label: <Divider/>},